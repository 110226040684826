import { BackgroundBlock } from "../../components/ui";

function OrgansPage() {
	const title = "Контролирующие органы";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-8 md:gap-16 justify-center items-center`}>
				<div className="flex flex-col gap-4 w-full">
					<div className="text-2xl font-bold w-full text-center">
						Учредитель и орган исполнительной власти субъекта РФ в сфере охраны здоровья
					</div>
					<div className="flex flex-col items-center justify-center md:flex-row gap-4">
						<img src="./svg/organs/comitet.svg" alt="comitet-icon" width={ 100 } height={ 100 }/>
						<div className="text-xl font-bold text-center md:text-left">Комитет по здравоохранению ЛО</div>
					</div>
					<div className="flex flex-col gap-4 w-full">
						<div>
							Официальный сайт: <a
							className='underline hover:no-underline font-bold'
							href={ `https://zdrav.lenobl.ru/` }
						>
							zdrav.lenobl.ru
						</a>
						</div>
						<div className="leading-6 font-bold">
							Для подачи обращения в Комитет по здравоохранению Ленинградской области по электронной почте
							заявителям необходимо в обязательном порядке указывать фамилию, имя, отчество и обратный
							почтовый адрес для ответа
						</div>
						<div className="font-bold">
							«Горячая линия» Комитета по здравоохранению Ленинградской области: <a
							className='font-bold hover:underline'
							href={ `tel:+78126796004` }
						>
							+7 (812)679-60-04
						</a>
						</div>
						<span>
							Юридический адрес: <span className="font-bold">191124, город Санкт-Петербург, Лафонская ул., д. 6 литера "а"</span>
						</span>
						<div>
							Адрес электронной почты: <a
							className='underline hover:no-underline'
							href={ `mailto:sec.lokz@lenreg.ru` }
						>
							sec.lokz@lenreg.ru
						</a>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4 w-full">
					<div className="text-2xl font-bold w-full text-center">
						Территориальный орган Федеральной службы по надзору в сфере здравоохранения
					</div>
					<div className="flex flex-col items-center justify-center md:flex-row gap-4">
						<img src="./img/organs/zdrav.png" alt="zdrav-icon" width={ 100 } height={ 100 }/>
						<div className="text-xl font-bold text-center md:text-left">Федеральная служба по надзору в сфере здравоохранения</div>
					</div>
					<div className="flex flex-col gap-4">
						<div>
							Официальный сайт: <a
							className='underline hover:no-underline font-bold'
							href={ `https://roszdravnadzor.gov.ru/` }
						>
							roszdravnadzor.gov.ru
						</a>
						</div>
						<div className="font-bold">
							Справочная Росздравнадзора: <a
							className='font-bold hover:underline'
							href={ `tel:+74995780220` }
						>
							+7 (499) 578-02-20
						</a>
						</div>
						<span>
							Адрес: <span className="font-bold">109012, Москва, Славянская площадь, д.4, стр.1</span>
						</span>
						<div>
							Адрес электронной почты: <a
							className='underline hover:no-underline'
							href={ `mailto:info@roszdravnadzor.gov.ru` }
						>
							info@roszdravnadzor.gov.ru
						</a>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<div className="text-2xl font-bold w-full text-center">
						Территориальный орган Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека
					</div>
					<div className="flex flex-col items-center justify-center md:flex-row gap-4">
						<img src="./img/organs/rospotreb.png" alt="rospotreb-icon" width={ 100 } height={ 100 }/>
						<div className="text-xl font-bold text-center md:text-left">Федеральная служба Роспотребнадзор</div>
					</div>
					<div className="flex flex-col gap-4">
						<div>
							Официальный сайт: <a
							className='underline hover:no-underline font-bold'
							href={ `https://47.rospotrebnadzor.ru/` }
						>
							47.rospotrebnadzor.ru
						</a>
						</div>
						<div className="font-bold">
							Горячая линия: <a
							className='font-bold hover:underline'
							href={ `tel:88124480400` }
						>
							8-812-448-04-00
						</a>
						</div>
						<div className="font-bold">
							Консультационный центр
							по защите прав потребителей: <a
							className='font-bold hover:underline'
							href={ `tel:88126796707` }
						>
							8-812-679-67-07
						</a>
						</div>
						<span>
							Адрес: <span className="font-bold">192029, г. Санкт-Петербург, ул. Ольминского, д. 27</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrgansPage;