import styled from "styled-components";

export const Button = styled.button`
    border-radius: 8px;
    background: #1167b1;
    color: #fff;
    padding: 16px;
    border: 1px solid #1167b1;

    &:hover:enabled{
        background: transparent;
        color: #1167b1;
    }
    
    &:disabled{
        background: #0a4273;
    }

    
    max-width: min-content;

`;