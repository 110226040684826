import { BackgroundBlock } from "../components/ui";

function EventsPage() {
	const title = "Мероприятия";

	const events = [
		{
			title: "Протокол совещания с субъектами Российской Федерации по вопросу развития и продвижения официального портала Минздрава России о здоровье",
			link: "./pdf/events/19.09.24.pdf"
		},
		{
			title: "Меры по предупреждению коррупции в государственных учреждениях и организациях Ленинградской области",
			link: "./pdf/events/anticorruption1.pdf"
		},
		{
			title: "Методические основы по организации работы по предупреждению коррупции в государственных организациях",
			link: "./pdf/events/anticorruption0.pdf"
		},
		{
			title: "Памятка по созданию контента для антинаркотической политики",
			link: "./pdf/events/24.05.24.pdf"
		},
		{
			title: "Год семьи 2024",
			link: "https://xn--2024-u4d6b7a9f1a.xn--p1ai/"
		},
		{
			title: "Профилактические медицинские осмотры и диспансеризация",
			link: "https://org.gnicpm.ru/profilakticheskie-mediczinskie-osmotry-i-dispanserizacziya-s-subtitrami"
		}
	];

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title } />
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				{
					events.map( el =>
						<a
							key={ `events-item-${ events.indexOf( el ) }` }
							className="leading-5 font-bold underline hover:no-underline md:text-justify"
							href={ el.link }
							target="_blank"
							rel="noreferrer"
						>
							{ el.title }
						</a>
					)
				}
			</div>

		</div>
	);
}

export default EventsPage;