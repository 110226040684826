import { BackgroundBlock } from "../../components/ui";
import { useNavigate } from "react-router-dom";

export const smoDepartments = [
	{
		key: "menu-item-rsmo-departments",
		displayName: "Районные судебно-медицинские отделения",
		href: "./pdf/contacts/contacts-rsmo.pdf"
	},
	{
		title: "Отдел комплексных экспертиз",
		href: "/otdel-kompleksnyh-ekspertiz",
		headPost: "Заведующая отделом: врач — судебно-медицинский эксперт высшей категории",
		headName: "Русанова Анна Иосифовна",
		headDisplayedPhone: ["8 (812) 230-08-15"],
		headPhone: ["88122300815"],
		secondPhoneName: "Телефон для справок",
		secondDisplayedPhone: "8 (812) 498-77-62",
		secondPhone: "88124987762",
		operatingMode: "ПН – ПТ  9:00 — 15:30",
		body: [
			"Адрес: г. Санкт-Петербург, ул. Ждановская, д. 43, лит. А.",
			<br/>,
			"Отдел комплексных экспертиз обеспечивает все виды деятельности в соответствии с Уставом Бюро и предметом деятельности отдела, осуществляя производство:",
			"• комиссионных и комплексных судебно-медицинских экспертиз (исследований) в рамках доследственных проверок, уголовных дел и дел об административных правонарушениях;",
			"• сложных первичных, дополнительных и повторных экспертиз (исследований) судебно- медицинских трупов, а также экспертиз (исследований) в отношении потерпевших, обвиняемых и других лиц;",
			"• сложных первичных, дополнительных и повторных судебно-медицинских экспертиз (исследований) вещественных доказательств, объектов биологического и другого происхождения;",
			"• дополнительных и повторных судебно-медицинских экспертиз (исследований) эксгумированных трупов;",
			"• судебно-медицинских экспертиз (исследований) в рамках доследственных проверок и уголовных дел о профессиональных правонарушениях медицинских работников;",
			"• судебно-медицинских экспертиз в рамках гражданского судопроизводства;",
			"• судебно-медицинских экспертиз (исследований), требующих привлечения специалистов клинического профиля, в рамках доследственных проверок, уголовных дел и дел об административных правонарушениях;",
			"• судебно-медицинских экспертиз при невозможности их производства в других отделах Бюро по процессуально-правовым и организационным причинам;",
			"• судебно-медицинских экспертиз в суде.",
			<br/>,
			"Кроме того, эксперты отдела комплексных экспертиз принимают участие:",
			"• в следственных действиях;",
			"• в оказании консультативной помощи работникам правоохранительных органов и судов, а также врачам судебно-медицинским экспертам Бюро, по профилю работы отдела;",
			"• в судебно-медицинском обеспечении по ликвидации последствий чрезвычайных ситуаций;",
			"• в приносящей доход деятельности, регламентированной Уставом Бюро."
		]
	},
	{
		title: "Отдел дежурной группы",
		href: "/otdel-dejurnoy-gruppi",
		headPost: "Заведующий отделом: врач I квалификационной категории",
		headName: "НИКИТИН АЛЕКСАНДР ВИКТОРОВИЧ",
		headDisplayedPhone: ["8 (964) 38-66-092"],
		headPhone: ["89643866092"],
		secondPhoneName: "Дежурный судебно-медицинский эксперт, дислоцированный в г. Санкт-Петербург, ул. Шкапина",
		secondDisplayedPhone: "8 (931) 858-15-86",
		secondPhone: "89318581586",
		operatingMode: "ПН-ПТ 16:00-08:00\nВ выходные и праздничные дни - круглосуточно",
		body: [
			"Дежурный отдел занимается следующей деятельностью:",
			"• обеспечивает  круглосуточное участие судебно-медицинских экспертов в качестве специалистов в области судебной медицины (в редких случаях в качестве судебно-медицинских экспертов) в осмотре трупа на месте его обнаружения (происшествия) с целью определения вида и рода смерти, выявления телесных повреждений и описания их, определения давности наступления смерти и т.д. Судебно-медицинский эксперт входит состав следственно-оперативной группы, возглавляемой следователем;",
			"• обеспечивает круглосуточное участие судебно-медицинских экспертов в качестве специалистов в области судебной медицины с целью выявления следов биологического происхождения, с последующих описанием их и помощи следователю в изъятии, с последующей устной консультацией о возможном механизме образования установленных следов;",
			"• проводит судебно-медицинское освидетельствования задержанных по подозрению в совершении преступлений против личности, с фиксацией установленных повреждений в протокол, заполняемый следователем. Также помогает следователю в изъятии биоматериала у подозреваемых;",
			"• проводит судебно-медицинского исследования трупов в экстренных случаях;",
			"• обеспечивает участие дежурных судебно-медицинских экспертов в составе следственно-оперативных групп при катастрофах, стихийных бедствиях с большим количеством человеческих жертв;",
			<br/>,
			"В рабочие дни, с 9 часов утра до 15 часов 30 минут – указанные функции выполняет судебно-медицинский эксперт районного (межрайонного) судебно-медицинского отделения:",
			"• с 16 часов 00 минут и до 08 часов 00 минут следующего дня указанные функции выполняет дежурный судебно-медицинский эксперт, дислоцированный в г. Санкт-Петербург, ул. Шкапина 36-38-40 литера Б, выезжает в следующие районы Ленинградской области: Волосовский и Гатчинский районы (в выходные и праздничные дни, в остальное время привлекаются судебно-медицинские эксперты указанных районов);  Всеволожский - территории Кузьмоловского, Сертоловского, Лесколовского ОП; Выборгский (только административную территорию Рощинского ОП, входят пос. Камышовка, пос. Красная Долина, пос. Рябово, пос. Краснофлотское, пос. Лужки, пос. Озерки, пос. Зеркальное, пос. Заречье); Кировский, Ломоносовский (в т.ч. числе г. Сосновый Бор и территории его района) и Тосненский районы;",
			"• в Бокситогорском, Волховском, Всеволожском (за исключением территории указанной выше), Выборгском (за исключением территории указанной выше), Киришском, Лужском, Приозерском и Тихвинском районах круглосуточно указанные функции выполняют судебно-медицинские эксперты, работающие в указанных районах."
		]
	},
	{
		title: "Отдел исследования вещественных доказательств и биологических объектов",
		href: "/otdel-issledovaniya-veshhestvennykh-dokazatelstv-i-biologicheskikh-obektov",
	},

];

function SmoDepartmentsPage() {
	const navigate = useNavigate();

	const title = "Судебно-медицинские отделения";
	const link = smoDepartments[0];

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				<div className="flex flex-col gap-4">
					<a
						key={ link.href.replace('/', '') }
						className="underline hover:no-underline cursor-pointer"
						href={ link.href }
						target="_blank"
						rel="noreferrer"
					>
						{ link.displayName }
					</a>
					{
						smoDepartments.slice(1,4).map(el => {
							return (
								<div
									key={ el.href.replace('/', '') }
									className="underline hover:no-underline cursor-pointer"
									onClick={ () => navigate(el.href) }
								>
									{ el.title }
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}

export default SmoDepartmentsPage;



