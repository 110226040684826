import styled from "styled-components";

import Breadcrumbs from "./breadcrumbs";
import { Button } from "./button";
import PhoneInput from "./phone-input";
import Burger, { BurgerTrigger } from "./burger";
import BackgroundBlock from "./background-block";
import { StyledH1 } from "./background-block";
import { LightText } from "./breadcrumbs";

const FormInput = styled.input`
    border-radius: 8px;
    outline: none;
    color: #3f3f3f;
    background: none;
    border: 1px solid #3f3f3f;
    
    padding: 16px; 
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    input[type=number] {
      -moz-appearance: textfield;
    }
`;

const FormSelect = styled.select`
    border-radius: 8px;
    outline: none;
    color: #3f3f3f;
    background: none;
    border: 1px solid #3f3f3f;
    
    padding: 16px; 
`;

const FormTextarea = styled.textarea`
    border-radius: 8px;
    outline: none;
    border: 1px solid #3f3f3f;
    height: 100%;
    color: #3f3f3f;
    background: none;
    
    padding: 16px; 
`;

export {
	Breadcrumbs,
	Button,
	PhoneInput,
	FormInput,
	FormTextarea,
	FormSelect,
	Burger,
	BurgerTrigger,
	BackgroundBlock,
	StyledH1,
	LightText
}