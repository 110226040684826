import { BackgroundBlock } from "../../components/ui";

function HonoredDoctorsPage() {
	const title = "Заслуженные врачи РФ";

	const honoredDoctors = [
		{
			key: "popov",
			name: "Попов Вячеслав Леонидович",
			post: "Врач — судебно-медицинский эксперт высшей квалификационной категории,\n" +
				"эксперт организационно-методического отдела,\n" +
				"доктор медицинских наук,\nпрофессор,\nЗаслуженный врач РФ",
			photo: "./img/honored-doctors/popov.jpg",
		},
		{
			key: "molin",
			name: "Чанцева Людмила Владимировна",
			post: "Врач — судебно-медицинский эксперт высшей квалификационной категории,\n" +
				"заведующая Тосненским РСМО,\n" +
				"Заслуженный врач РФ",
			photo: "./img/honored-doctors/chanceva.jpeg",
		},
	];

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="flex gap-16 lg:flex-row flex-col justify-around">
					{
						honoredDoctors.map(
							el =>
								<div
									className="flex flex-col gap-4 flex-1 items-center"
									key={ el.key }
								>
									<img
										style={ {height: 400} }
										src={ el.photo }
										alt={ el.key }
									/>
									<div className=" text-lg font-bold uppercase text-center">
										{ el.name }
									</div>
									<div className="text-sm font-bold text-center whitespace-pre-line leading-5">
										{ el.post }
									</div>
								</div>
						)
					}
				</div>
			</div>
		</div>
	);
}

export default HonoredDoctorsPage;