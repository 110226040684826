import { BackgroundBlock } from "../../components/ui";

function DepartmentUniversalPage(props) {
	const {
		title,
		headPost,
		headName,
		headPhone,
		headDisplayedPhone,
		operatingMode,
		body,
		secondPhone,
		secondDisplayedPhone,
		secondPhoneName,
		isAUP,
		isSMO
	} = props;

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock
				title={ title }
				links={
					isAUP
						? [
							{
								href: "/administrativno-upravvlencheskij-personal",
								name: "Административно-управленческий персонал"
							}
						]
						:
						isSMO
							? [
								{
									href: "/sudebno-mediczinskie-otdely",
									name: "Судебно-медицинские отделения"
								}
							]
							: [
								{
									href: "/sudebno-mediczinskie-otdely",
									name: "Судебно-медицинские отделения"
								},
								{
									href: "/otdel-issledovaniya-veshhestvennykh-dokazatelstv-i-biologicheskikh-obektov",
									name: "Отдел исследования вещественных доказательств и биологических объектов"
								},
							]
				}
			/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				<div className="text-justify font-bold text-lg">
					{ headPost }
				</div>
				<div className="font-bold text-xl uppercase">
					{ headName }
				</div>
				{
					headPhone &&
					<span className="flex gap-2">
					Телефон:
						{
							headPhone.map(phone => <a
								className='font-bold hover:underline'
								href={ `tel:${ phone }` }
								key={ `department-phone-${ headPhone.indexOf(phone) }` }
							>
								{ headDisplayedPhone[headPhone.indexOf(phone)] }
							</a>)
						}
				</span>
				}
				{
					secondPhone &&
					<div>
						{ secondPhoneName }: <a className='font-bold hover:underline'
						                        href={ `tel:${ secondPhone }` }>
						{ secondDisplayedPhone }
					</a>
					</div>
				}
				{
					operatingMode &&
					<div>
						Режим работы: <span className='font-bold'>{ operatingMode }</span>
					</div>
				}
				{
					body && body.length
						? <div className="flex flex-col gap-4">
							<br/>
							{ body.map(el => el.length > 1 ? <div key={ `body-item-${ body.indexOf(el) }` }
							                                      className="leading-6 text-justify">{ el }</div> :
								<br key={ `body-item-${ body.indexOf(el) }` }/>) }
						</div>
						: <></>

				}
			</div>
		</div>
	)
}

export default DepartmentUniversalPage;