import './App.css';
import { Footer, Header } from "./components";
import ScrollToTop from "./components/utils/scroll-to-top";
import { Burger } from "./components/ui";
import { menu } from "./components/header";
import { useEffect, useState } from "react";
import { AppRoutes } from "./routes";

function App() {
	const [isBurgerOpened, setIsBurgerOpened] = useState(false);
	const [isVisionMode, setIsVisionMode] = useState(localStorage.getItem('vision') === '1');

	useEffect(() => {
		document
			.documentElement
			.style
			.setProperty(
				'--global-font-size',
				isVisionMode ? '28px' : '20px');
	}, [isVisionMode])
	return (
		<div className="App">
			<ScrollToTop/>

			<div className="App-inner flex flex-col">
				<Header
					setIsBurgerOpened={ setIsBurgerOpened }
					isVisionMode={ isVisionMode }
					setIsVisionMode={ setIsVisionMode }
				/>

				<AppRoutes/>

				<div className="flex h-full flex-col justify-end">
					<Footer/>
				</div>
			</div>
			<Burger
				items={ menu }
				isOpened={ isBurgerOpened }
				setIsOpened={ setIsBurgerOpened }
			/>
			<div id="success" className="snackbar bg-green-400 text-green-900 text-2xl">
				Ваша заявка принята
			</div>
			<div id="error" className="snackbar bg-red-400 text-red-900 text-2xl">
				Произошла ошибка!
			</div>
			<div id="captcha" className="snackbar bg-yellow-400 text-yellow-900 text-2xl">
				Пожалуйста, решите капчу!
			</div>

		</div>
	);
}

export default App;
