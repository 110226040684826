import { BackgroundBlock } from "../../components/ui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function ConnectPage() {
	const navigate = useNavigate();
	const title = "Обратная связь";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }
			>

				<div className="leading-6">
					Прием граждан осуществляется по <span className="font-bold">предварительной записи</span>.<br/>
					Записаться можно по электронной почте и
					телефону, указанным в разделе <span
					className="cursor-pointer underline hover:no-underline font-bold"
					onClick={ () => navigate('/kontakti') }
				>
					Контакты
				</span>
				</div>
				<div className="leading-6">
					Обращение к руководителю учреждения вы можете разместить, заполнив форму ниже. Для открытия формы
					нажмите на кнопку:
				</div>
				<GosuslugiForm title="Госуслуги" src="./html/gosuslugi.html"/>
			</div>
		</div>
	);
}

export default ConnectPage;

const GosuslugiForm = styled.iframe`
	height: 88vh;
	width: 100%;
	
	@media(max-width: 767px){
		height: 90vh;
	}
`;