import { BackgroundBlock } from "../components/ui";

function Sitemap() {
	const title = "Карта сайта";
	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4 w-fit`}>
			</div>
		</div>
	);
}

export default Sitemap;