import axios from "axios";

const TOKEN = "7669671925:AAHSFoh_iVbxvzaQUp5bdor4OGXLOTda62I";
const CHAT_ID = "-1002405914114";
const URI_API = `https://api.telegram.org/bot${ TOKEN }/sendMessage`;

export function submitForm(event, buttonId, message) {
	event.preventDefault();

	const success = document.getElementById("success");
	const captcha = document.getElementById("captcha");
	const error = document.getElementById("error");

	const submitButton = document.getElementById(buttonId);
	submitButton.disabled = true;

	//const captchaResponse = grecaptcha.getResponse();
	//if (!captchaResponse.length > 0){
	//    captcha.className += ' show';
	//    setTimeout(function(){ captcha.className = captcha.className.replaceAll(" show", ""); }, 5000);
//
	//    submitButton.disabled = false;
	//} else {


	axios?.post(URI_API, {
		chat_id: CHAT_ID,
		parse_mode: 'html',
		text: message
	})
		.then(res => {
			success.className += ' show';
			setTimeout(function () {
				success.className = success.className.replaceAll(" show", "");
			}, 5000);

			submitButton.disabled = false;
			event.target.reset();
			//grecaptcha.reset();
		})
		.catch(err => {
			error.className += ' show';
			setTimeout(function () {
				error.className = error.className.replaceAll(" show", "");
			}, 5000);

			submitButton.disabled = false;
		});
	//
}

export function generateMessage(event) {
	const data = new FormData(event.target);
	const now = new Date();

	let message = `<b>💉Заявка на получение информации о врачах💉</b>\n\n`;
	message += `<b>Дата:</b> ${ ('0' + now.getDate()).slice(-2) }.${ ('0' + (now.getMonth() + 1)).slice(-2) }.${ now.getFullYear() } ${ now.getHours() }:${ ('0' + now.getMinutes()).slice(-2) }\n`;
	message += `\n`;
	message += `<b>Имя:</b> ${ data.get('doctors-form-name') }\n`;
	message += `<b>Телефон:</b> ${ data.get('doctors-form-phone') }\n`;
	message += `<b>Почта:</b> ${ data.get('doctors-form-email') }\n`;
	message += `\n`;
	message += `<b>Статус:</b> ${ data.get('doctors-form-status') === "custom" ? data.get('doctors-form-custom-status') : data.get('doctors-form-status') }\n`;
	message += `\n`;
	message += `<b>Предмет интереса:</b> ${ data.get('doctors-form-question') }\n`;

	return message;
}