import styled from "styled-components";
import { BackgroundBlock } from "../../components/ui";

function ContactsPage() {
	const title = "Контакты";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="leading-6">
					Почтовый адрес: 198095, Ленинградская область, г. Санкт-Петербург, ул. Шкапина, д. 36-38-40 литер «Б»<br/>
					Юридический адрес: 188300, Ленинградская область, г. Гатчина, ул. Рощинская, д. 15А, корп. 8, пом. 2<br/>
					Телефон: <a className='font-bold hover:underline' href="tel:+78122523124">+7 (812)
					252-31-24</a><br/>
					Факс: <span
					className='font-bold'> +7 (812) 252-49-33</span><br/>
					E-mail: <a className="underline hover:no-underline"
					           href="mailto:expertfm@mail.ru">expertfm@mail.ru</a><br/>
					Режим работы:<br/>
					Ежедневно с <span className='font-bold'>9:00</span> до <span className='font-bold'>17:30</span> (кроме выходных и праздничных дней)
				</div>
				<div className="leading-6">
					Прием и выдача документов в канцелярии — ежедневно с <span className='font-bold'>9:00</span> до <span className='font-bold'>17:00</span> (кроме выходных и праздничных
					дней)
				</div>

				<div className="leading-6">
					ОТДЕЛ ДЕЖУРНОЙ ГРУППЫ: (только для сотрудников правоохранительных органов)<br/>
					Режим работы:<br/>
					В будние дни с <span className='font-bold'>16:00</span> до <span className='font-bold'>09:00</span>, выходные — <span className='font-bold'>круглосуточно</span><br/>

				</div>
				<div className="leading-6">
					Телефон дежурной группы: <a className='font-bold hover:underline' href="tel:+79218581586">+7 921
					858-15-86</a><br/>
					Телефон руководителя дежурной группы: <a className='font-bold hover:underline'
					                                         href="tel:+79643866092">+7
					964 386-60-92</a>
				</div>
				<ContactsMap>
					<div className="font-bold text-lg">
						Схема проезда
					</div>
					<iframe
						src="https://yandex.ru/map-widget/v1/?um=constructor%3Aacf8ecbc5cef51bcbcd902fb092a870d681583b895d9a332fcc1751e181ee91d&amp;source=constructor"
						width="100%"
						height="400"
						frameBorder="0"
						title="Схема проезда"
					/>
				</ContactsMap>
				<div className="text-sm leading-5">
					Сведения, размещенные на сайте, носят информационный характер и не являются публичной офертой. <br/>
					Несогласованное использование материалов сайта влечет за собой административную ответственность.
				</div>
			</div>
		</div>
	);
}

export default ContactsPage;

export const ContactsMap = styled.div`
	border-top: 1px solid #3F3F3F;
	border-bottom: 1px solid #3F3F3F;
	padding: 1rem 0 1rem 0;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;