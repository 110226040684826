import {
	BackgroundBlock,
	Button,
	FormInput,
	FormSelect,
	FormTextarea,
	PhoneInput,
} from "../../components/ui";
import { generateMessage, submitForm } from "../../components/utils/submit";
import { useState } from "react";

function DoctorsPage() {
	const title = "Информация о врачах";
	const buttonId = "submit";

	const statuses = [
		"Гражданин",
		"Свидетель",
		"Потерпевший",
		"Подозреваемый",
		"Обвиняемый",
		"Осужденный",
		"Истец",
		"Ответчик",
		"Представитель",
		"Адвокат"
	];

	const [customStatusVisible, setCustomStatusVisible] = useState(false);

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				<div className="leading-6 text-justify">
					Поскольку медицинские работники нашего учреждения являются участниками судопроизводства и могут быть
					объектами государственной защиты,
					их данные предоставляются по запросу, в соответствии со ст. 2 Федерального закона от 02 мая 2006 г.
					№59-Ф3 «О порядке рассмотрения обращений граждан Российской Федерации».
					Запрос рассматривается с участием специалиста по информационной безопасности.
				</div>
				<div className="leading-6">
					Для формирования запроса заполните форму ниже.
				</div>
				<form
					className="flex flex-col gap-4 md:mt-4"
					onSubmit={ e => submitForm(e, buttonId, generateMessage(e)) }
				>
					<div className="font-bold text-lg">Заявка на получение информации о врачах</div>
					<div className="flex flex-col gap-4 flex-1">
						<div className="flex flex-col gap-2">
							<label className="text-xs leading-3" htmlFor="doctors-form-name">
								ФИО*
							</label>
							<FormInput
								type="text"
								name="doctors-form-name"
								placeholder="Иванов Иван Иванович"
								required
							/>
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-xs leading-3" htmlFor="doctors-form-phone">
								Номер телефона*
							</label>
							<PhoneInput required name="doctors-form-phone"/>
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-xs leading-3" htmlFor="doctors-form-email">
								Адрес электронной почты*
							</label>
							<FormInput
								required
								type="email"
								name="doctors-form-email"
								placeholder="example@mail.com"
							/>
						</div>
						<div className="flex flex-col gap-2">
							<label className="text-xs leading-3" htmlFor="doctors-form-status">
								Статус*
							</label>
							<FormSelect
								name="doctors-form-status"
								required
								onChange={ (event) => {
									setCustomStatusVisible(event.target.value === "custom");
								} }
							>
								{
									statuses.map(status =>
										<option
											key={ status }
											value={ status }
										>
											{ status }
										</option>)
								}
								<option value="custom">Свое значение</option>
							</FormSelect>
						</div>
						{
							customStatusVisible &&
							<div className="flex flex-col gap-2">
								<label className="text-xs leading-3" htmlFor="doctors-form-custom-status">
									Ваш статус*
								</label>
								<FormInput
									required={ customStatusVisible }
									type="text"
									name="doctors-form-custom-status"
									placeholder="Подозреваемый"
								/>
							</div>
						}
						<div className="flex flex-col gap-2 flex-1">
							<label className="text-xs leading-3" htmlFor="doctors-form-question">
								Опишите предмет интереса*
							</label>
							<FormTextarea
								name="doctors-form-question"
								required
								placeholder="Введите сообщение"
								rows={ 8 }
							/>
						</div>
						<div className="text-sm leading-4">
							Нажимая на кнопку, вы даете согласие на обработку персональных данных
							{/*Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь с <a*/ }
							{/*className="text-sm underline" href="/">политикой конфиденциальности</a>*/ }
						</div>
					</div>
					<div className="flex w-full justify-center items-center">
						<Button type="submit" id={ buttonId }>
							Отправить
						</Button>
					</div>

				</form>
			</div>
		</div>
	);
}

export default DoctorsPage;