import { BackgroundBlock } from "../../components/ui";
import { useNavigate } from "react-router-dom";

export const aupDepartments = [
	{
		title: "Администрация",
		href: "/administraciya"
	},
	{
		title: "Организационно-методический отдел",
		href: "/organizacionno-metodicheskiy-otdel",
		headPost: "Заместитель начальника по организационно-методической работе: врач — судебно-медицинский эксперт высшей квалификационной категории",
		headName: "Костюченко Константин Игоревич",
		headDisplayedPhone: ["8 (812) 251-44-94", "8 (812) 251-29-11"],
		headPhone: ["88122514494", "88122512911"],
		operatingMode: "ПН – ПТ  9:00 — 16:45,  перерыв 12:00 — 12:30",
		body: [
			"Основные задачи отдела:",
			"• планирование работы, организация документооборота, разработка мероприятий, направленных обеспечение качества и своевременности выполнения экспертиз;",
			"• осуществление процедуры лицензирования Бюро;",
			"• организация системы контроля за выполнением планов, программ, приказов, распоряжений и решений, проверок деятельности структурных подразделений;",
			"• анализ и обобщение результатов деятельности Бюро, результатов проверок подразделений и доведение их до сведения руководства Бюро и экспертов с разработкой статистических данных и рекомендаций по улучшению работы Бюро и качества медицинской помощи населению;",
			"• разработка и внедрение применительно к судебно-медицинской экспертизе новых форм организации труда, нормирование экспертной работы;",
			"• организация и обеспечение взаимодействия подразделений учреждения;",
			"• разработка и внедрение системы контроля качества экспертной деятельности, рационального применения апробированных методов исследования;",
			"• внедрение в экспертную практику новых достижений медицинской науки и техники, усовершенствованных судебно-медицинских технологий, научных программ по судеб- но-медицинской экспертизе;",
			"• своевременное доведение до сведения судебно-медицин- ских экспертов правил, инструкций, инструктивно-методических материалов;",
			"• содействие в работе территориального отделения Всероссийского общества судебных медиков по разработке научных направлений в области судебной медицины и внедрению их в практику;",
			"• организация работы по своевременному повышению профессионального уровня врачей — судебно-медицинских экспертов, среднего и младшего медицинского персонала;",
			"• организация и проведение научно-практических конференций и семинаров;",
			"• подготовка проектов информационных писем по вопросам улучшения качества экспертиз и исследований;",
			"• разработка и реализация системы мероприятий по аттестации врачей — судебно-медицинских экспертов и лаборантов Бюро;",
			"• организация и осуществление консультативной помощи органам исполнительной власти, правоохранительным и судебным органам, сотрудникам медицинских учреждений по вопросам производства и качества судебно-медицинской экспертизы;",
			"• организация работы с письмами и обращениями граждан и организаций;",
			"• оказание консультативной помощи судебно-медицинским экспертам по вопросам порядка и организации производства и обеспечения качества экспертиз;",
			"• организация работы методического совета;",
			"• организация и пополнение библиотечного фонда Бюро;",
			"• организация и хранение архива Бюро. "
		]
	}
];

function AupDepartmentsPage() {
	const navigate = useNavigate();

	const title = "Административно-управленческий персонал";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="flex flex-col gap-4">
					{
						aupDepartments.map(el => {
							return (
								<div
									key={ el.href.replace('/', '') }
									className="underline hover:no-underline cursor-pointer"
									onClick={ () => navigate(el.href) }
								>
									{ el.title }
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}

export default AupDepartmentsPage;



