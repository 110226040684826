import { BackgroundBlock } from "../../components/ui";
import { useNavigate } from "react-router-dom";

export const medDepartments = [
	{
		title: "Судебно-химическое отделение",
		href: "/sudebno-himicheskoe-otdelenie",
		headPost: "Заведующая отделением: судебный эксперт высшей квалификационной категории",
		headName: "Шанская Юлия Борисовна",
		headDisplayedPhone: ["8 (812) 251-26-11"],
		headPhone: ["88122512611"],
		operatingMode: "ПН – ПТ  9:00 — 15:30",
		body: [
			"В отделении выполняются следующие виды экспертиз: судебно-химические; биохимические.",
			<br/>,
			"Основные решаемые задачи:",
			"• определение этанола в биологических объектах (кровь, моча, мышечная ткань);",
			"• определение летучих веществ (метилового, пропиловых, бутиловых, амиловых спиртов, растворителей, технических жидкостей) в биологических объектах;",
			"• определение газов (пропаны, бутаны) в биологических объектах; ",
			"• определение карбоксигемоглобина в крови;",
			"• определение наркотических средств, психотропных, сильнодействующих веществ в биологических объектах (кровь, моча, внутренние органы, кожа, волосы, ногти);",
			"• определение кислот, щелочей в биологических объектах;",
			"• исследование небиологических объектов с целью определения наркотических, психотропных, сильнодействующих и летучих веществ;",
			"• биохимические исследования в биологических объектах.\n"
		]
	},
	{
		title: "Отделение судебно-биологических и молекулярно-генетических исследований",
		href: "/otdelenie-sudebno-biologicheskih-i-molekulyarno-geneticheskih-issledovanij",
		headPost: "Заведующая отделением: врач высшей квалификационной категории",
		headName: "Мазурова Елена Анатольевна",
		headDisplayedPhone: ["8 (812) 251-40-00"],
		headPhone: ["88122514000"],
		secondPhoneName: "Регистратура – телефон",
		secondDisplayedPhone: "8 (812) 252-24-85",
		secondPhone: "88122522485",
		operatingMode: "ПН – ПТ  9:00 — 15:30",
		body: [
			"В отделении проводятся экспертизы:",
			"• судебно-биологическая",
			"• судебно-цитологическая",
			"• генетическая",
			<br/>,
			"Объектами экспертного исследования являются:",
			"кровь, сперма, слюна, волосы, трупный биологический материал (части тела и другие объекты от трупов людей, в т.ч. и расчлененных трупов, отдельные кости, фрагменты костей, мягкие ткани, жидкая кровь и выделения, высохшие следы крови и выделений, зубы, кожа, ногтевые пластины, волосы и др.).",
			<br/>,
			"Основные задачи, решаемые в  ходе  экспертных исследований:",
			"• определение наличия, видовой, групповой, половой и региональной принадлежности крови;",
			"• определение наличия и групповой принадлежности спермы, слюны, пота;",
			"• определение наличия, видовой, групповой, половой, региональной принадлежности волос;",
			"• определение наличия, видовой, групповой, половой принадлежности клеток, частиц органов и тканей, установление их органно-тканевого происхождения;",
			"• установление принадлежности крови, спермы, слюны, волос, тканей, органов и отделенных частей тела конкретному лицу;",
			"• установление истинных родителей ребенка по делам о спорном происхождении детей (оспаривание отцовства, материнства, подмена детей и др.)."
		]
	},
	{
		title: "Судебно-гистологическое отделение",
		href: "/sudebno-gistologicheskoe-otdelenie",
		headPost: "Заведующая отделением: врач — судебно-медицинский эксперт",
		headName: "Хорошева Татьяна Константиновна",
		headDisplayedPhone: ["8 (812) 252-57-14"],
		headPhone: ["88122525714"],
		operatingMode: "ПН – ПТ  9:00 — 15:30",
		body: [
			"В судебно-гистологическом отделении проводятся экспертизы для установления наличия и оценки патологических изменений в органах и тканях, обусловленных повреждениями или заболеваниями, определения прижизненности и давности причинения телесных повреждений, а также решения иных вопросов, разрешение которых требует изучения микроскопической структуры органов и тканей человека." ,
			<br/>,
			"Экспертные исследования выполняются при проведении судебно-медицинской экспертизы трупа, судебно-медицинской экспертизы живых лиц, судебно-медицинской экспертизы по материалам дела."
		]
	},
	{
		title: "Медико-криминалистическое отделение",
		href: "/mediko-kriminalisticheskoe-otdelenie",
		headPost: "Заведующий отделением: врач высшей квалификационной категории, к.м.н.",
		headName: "Хуснутдинов Андрей Раифович",
		headDisplayedPhone: ["8 (812) 251-31-00", "8 (812) 251-45-98"],
		headPhone: ["88122513100", "88122514598"],
		operatingMode: "ПН – ПТ  9:00 — 15:30",
		body: [
			"Медико-криминалистическое отделение  производит исследование вещественных доказательств и биологических объектов с целью решения идентификационных, диагностических и ситуационных экспертных задач.",
			<br/>,
			"Деятельность отделения:",
			"• трасологические исследования;",
             "• баллистические исследования;",
             "• антропологические и антропометрические исследования;",
             "• микрологические исследования;",
             "• ситуационные исследования;",
             "• прочие исследования (исследование рентгенограмм);",
             "• рентгеноспектральные исследования.",

]
	},

];

function MedDepartmentsPage() {
	const navigate = useNavigate();

	const title = "Отдел исследования вещественных доказательств и биологических объектов";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock
				title={ title }
				links={
					[
						{
							href: "/sudebno-mediczinskie-otdely",
							name: "Судебно-медицинские отделения"
						},
					]
				}
			/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				<div className="flex flex-col gap-4">
					{
						medDepartments.map(el => {
							return (
								<div
									key={ el.href.replace('/', '') }
									className="underline hover:no-underline cursor-pointer"
									onClick={ () => navigate(el.href) }
								>
									{ el.title }
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}

export default MedDepartmentsPage;



