import { useMask } from "@react-input/mask";
import { FormInput } from "./";

function PhoneInput(props){
	const {
		name
	}  = props;
	const phoneMask = useMask({ mask: '+7 (___) ___-__-__', replacement: { _: /\d/ } });

	return (
		<FormInput
			name={name}
			placeholder="+7 (987) 654-32-10"
			ref={ phoneMask }
			minLength={17}
			maxLength={17}
		/>
	);
}
export default PhoneInput;