import { Breadcrumbs } from "./index";
import styled from "styled-components";

function BackgroundBlock( props ) {
	const {title, links} = props;

	return (
		<BlockWithBackgroundColor>
			<StyledH1 className="font-bold text-xl md:text-3xl text-center">{ title }</StyledH1>
			<Breadcrumbs lastChild={ title } links={ links }/>
		</BlockWithBackgroundColor>
	);

}

export default BackgroundBlock;

export const BlockWithBackgroundColor = styled.div`
	background: #008631;
	
	width: 100%;
	height: min-content;
	padding: 2rem 12rem;
	
	display: flex;
	flex-direction: column;
	
	@media(max-width: 1023px){
		padding: 2rem 3rem;
	}
	
	@media(max-width: 767px){
		padding: 0.75rem 1rem;
		
		gap: 1rem;
	}
`;


export const StyledH1 = styled.h1`
	color: #f3f3f3;
`;